<template>
  <div>
    <AppBar :title="$t('customers.b2b')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-text>
        <v-row>
          <v-col cols='4'>
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('customers.searchPlaceholder')" single-line clearable hide-details></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
      :headers="headers"
      :items="customers"
      :items-per-page="25"
      :loading="loading"
      :search="search">
        <template v-slot:item.created="{ item }">
          {{ item.created | dateFormat('YYYY-MM-DD') }}
        </template>
        <template v-slot:item.isPartner="{ item }">
          <v-chip small v-if="item.isPartner">{{ $t('customers.partner') }}</v-chip>
        </template>
        <template v-slot:item.phone="{ item }">
          ({{ item.phone.countryCallingCode }}) {{ item.phone.number }}
        </template>
        <template v-slot:item.edit="{ item }">
          <v-btn depressed @click="$router.push({ name: 'BusinessCustomer', params: { id: item.id } })" rounded>{{ $t('customers.headers.edit') }}</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'BusinessCustomers',
  data () {
    return {
      loading: false,
      customers: [],
      search: '',
      headers: [
        {
          text: '',
          width: '100',
          value: 'isPartner'
        },
        {
          text: this.$t('customers.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('customers.headers.surname'),
          value: 'surname'
        },
        {
          text: this.$t('customers.headers.company'),
          value: 'companyName'
        },
        {
          text: this.$t('customers.headers.email'),
          value: 'email'
        },
        {
          text: this.$t('customers.headers.city'),
          value: 'city'
        },
        {
          text: this.$t('customers.headers.phone'),
          value: 'phone'
        },
        {
          text: this.$t('customers.headers.registration'),
          value: 'created'
        },
        {
          text: this.$t('customers.headers.status'),
          value: 'state'
        },
        {
          sortable: false,
          align: 'end',
          width: '100',
          value: this.$t('customers.headers.edit')
        }
      ]
    }
  },
  async created () {
    await this.getCustomers()
  },
  methods: {
    async getCustomers () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`BusinessCustomers?countryCode=${countryCode}`)
        this.customers = data
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
