<template>
  <div>
    <AppBar :title="$t('customers.uae')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-btn depressed small :to="'/statistics-uae'" color="secondary" rounded>{{ $t('customers.headers.statistics') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
      :headers="headers"
      :items="customers"
      :items-per-page="25"
      :loading="loading"
      :search="search">
        <template v-slot:item.phone="{ item }">
          ({{ item.phone.countryCallingCode }}) {{ item.phone.number }}
        </template>
        <template v-slot:item.edit="{ item }">
          <v-btn depressed @click="$router.push({ name: 'CustomerUAE', params: { id: item.id } })" rounded>{{ $t('users.edit') }}</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'PrivateCustomers',
  data () {
    return {
      loading: false,
      customers: [],
      headers: [
        {
          text: this.$t('customers.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('customers.headers.surname'),
          value: 'surname'
        },
        {
          text: this.$t('customers.headers.email'),
          value: 'email'
        },
        {
          text: this.$t('customers.headers.phone'),
          value: 'phone'
        },
        {
          text: this.$t('customers.headers.status'),
          value: 'state.name'
        },
        {
          sortable: false,
          align: 'end',
          width: '100',
          value: 'edit'
        }
      ],
      search: ''
    }
  },
  async created () {
    await this.getCustomers()
  },
  methods: {
    async getCustomers () {
      this.loading = true
      try {
        const { data } = await Service.get('DubaiCustomers')
        this.customers = data
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
