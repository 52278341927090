var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppBar',{attrs:{"title":_vm.$t('customers.individual')}}),_c('v-card',{staticClass:"rounded-xl pt-2 pb-4",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('customers.searchPlaceholder'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"items-per-page":25,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created,'YYYY-MM-DD'))+" ")]}},{key:"item.isVerified",fn:function(ref){
var item = ref.item;
return [(item.isVerified)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('customers.verifiedChip')))]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" ("+_vm._s(item.phone.countryCallingCode)+") "+_vm._s(item.phone.number)+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","rounded":""},on:{"click":function($event){return _vm.$router.push({ name: 'PrivateCustomer', params: { id: item.id } })}}},[_vm._v(_vm._s(_vm.$t('common.edit')))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }